import React, { useState } from 'react'


function FAQs({question, answer}){
    const [visibleQuestions, setVisibleQuestions] = useState(4);
    const loadMore = () => {
        setVisibleQuestions(faqs.length);
      };
    const faqs = [
        {
            question:"What is Saarthi AI Pvt Ltd?",
            answer:"Saarthi AI Pvt Ltd is a technology-based platform that connects customers with local businesses, offering a curated selection of products and services that promote healthy living, sustainability, and cultural authenticity."
        },
        {
            question:"How does Saarthi AI work?",
            answer:"Saarthi AI utilizes artificial intelligence algorithms to personalize the shopping experience, providing users with tailored product recommendations, content, and user interfaces based on their preferences and behavior."
        },
        {
            question:"What kinds of products are available on Saarthi AI?",
            answer:"Saarthi AI offers a wide range of products, including locally-made handicrafts, eco-friendly goods, organic foods, wellness supplements, and cultural experiences from different regions of India."
        },
        {
            question:"Are the products on Saarthi AI sustainable and eco-friendly?",
            answer:"Yes, Saarthi AI prioritizes sustainability and eco-friendliness, showcasing products that are made using environmentally-conscious practices, sustainable materials, and ethical production methods."
        },
        {
            question:"How can I shop on Saarthi AI?",
            answer:"To shop on Saarthi AI, simply browse through the platform's offerings, add desired items to your cart, and proceed to checkout. You can also use the AI-powered search and recommendation features to discover new products based on your preferences."
        },
        {
            question:"Does Saarthi AI offer delivery services?",
            answer:"Yes, Saarthi AI provides convenient delivery options, including same-day delivery, scheduled delivery slots, and contactless delivery, to ensure that your orders are delivered to your doorstep in a timely and efficient manner."
        },
        {
            question:"Can I return or exchange products purchased on Saarthi AI?",
            answer:"Yes, Saarthi AI offers a hassle-free return and exchange policy. If you are not satisfied with your purchase for any reason, you can return the item(s) within a specified period for a refund or exchange."
        },

    ]
    
  return (
    <div className="w-full h-3/4 mx-auto p-4 rounded-lg shadow-lg" id='faq'>
      <h2 className="text-2xl font-semibold mb-4 text-center">Frequently Asked Questions</h2>
      {/* FAQ Items in a Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2  gap-5 place-items-center place-self-center">
        {faqs.slice(0, visibleQuestions).map((faq, index) => (
          <div key={index} className="p-4 bg-white rounded-md  text-center w-1/2 shadow-sm">
            <h3 className="font-medium text-lg text-gray-900">{faq.question}</h3>
            <p className="text-gray-700 mt-2">{faq.answer}</p>
          </div>
        ))}
      </div>

      {/* Learn More Button */}
      {visibleQuestions < faqs.length && (
        <button
          onClick={loadMore}
          className="mt-6 w-full py-2 text-black font-semibold rounded-md  focus:outline-none focus:ring-2 "
        >
          Learn More
        </button>
      )}
    </div>
  );
}
export default FAQs