import React, { useRef } from 'react';
import { motion } from "framer-motion";
import { FaLinkedin, FaInstagram } from 'react-icons/fa6';
// variants
import { fadeIn } from "../variants";



export default function Contact() {
    const Email = "intellicartcommunications@gmail.com";
    const linkedinprofileurl = ' https://www.linkedin.com/company/saarthi-ai-pvt-ltd/';
    const instagramprofileurl = 'https://www.instagram.com/saarthi__ai_pvt.ltd?igsh=MW1oYTc0OGQ0d3NnMg==';
    const aboutusRef = useRef(null);
    const [result, setResult] = React.useState("");
  
    const onSubmit = async (event) => {
      event.preventDefault();
      setResult("Sending....");
      const formData = new FormData(event.target);
  
      formData.append("access_key", "4b295dc3-1a3a-46d8-b0b7-edb0ab3af14a");
  
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formData
      });
    //   const [formData, setFormData] = useState({
    //     name: '',
    //     lastname: '',
    //     email: '',
    //     message: '',
    //   });
    //   const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setFormData({
    //       ...formData,
    //       [name]: value,
    //     });
    //   };
  
      const data = await response.json();
  
      if (data.success) {
        setResult("Form Submitted Successfully");
        event.target.reset();
      } else {
        console.log("Error", data);
        setResult(data.message);
      }
    };
    return (
      <div>
       <div className='  bg-neutralSilver h-3/4  md:flex-row flex-col flex' id='contact'>
        
        <div>
          <h1 className=' md:text-4xl font-semibold  text-gray-800  justify-between items-center'>Get In Touch</h1>
        </div>
        <motion.div 
    
          variants={fadeIn("up", 0.2)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.6 }}
          className="flex items-center justify-center lg:w-2/5 mx-auto">
         <form onSubmit={onSubmit} className=" flex flex-col space-y-4 p-4 " >
          <div>
            <label htmlFor="name" className="block text-xs font-medium text-gray-700">
             Name
            </label>
            <input type="text" name="name" required  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"/>
          </div>
          <div>
           <label htmlFor="lastname" className="block text-xs font-medium text-gray-700">
             Last Name
           </label>
           <input type="text" name="lastname" required  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"/>
        </div>
          <div>
           <label htmlFor="email" className="block text-xs font-medium text-gray-700">
             Email
           </label>
           <input type="email" name="email" required  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"/>
          </div>
          <div>
           <label htmlFor="message" className="block text-xs font-medium text-gray-700">
             Message
           </label>
           <textarea name="message" required className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"></textarea>
          </div>

         <button type="submit" className="  bg-brandPrimary text-white font-bold py-2  rounded-2xl w-1/2">Submit</button>

      </form>
      <span>{result}</span>
      </motion.div>

    </div>
    <section  className="flex flex-col gap-7  mb-16  mt-36 justify-center items-center">
         <div>
            <h1 className=" font-extrabold  text-3xl">Contact us</h1>
         </div>
         <div>
            <a href={`mailto:${Email}`} className=" link-underline text-xs  underline hover:scale-105 duration-200 link-underline hover:text-sm">
              @intellicartcommunications@gmail.com
            </a>
         </div>
         <div className="flex">
             <a
             href={linkedinprofileurl}
             target="_blank"
             rel="noopener noreferrer"
             className="text-2xl m-3 hover:cursor-pointer transition-all hover:text-blue-900 " >
              <FaLinkedin></FaLinkedin>
            </a>
            <a
              href={instagramprofileurl}
              target="_blank"
              rel="noopener noreferrer"
              className="text-2xl m-3 hover:cursor-pointer transition-all hover:text-pink-500 " >
              <FaInstagram></FaInstagram>
            </a>
          </div>

      </section>
  </div>
  );
}
