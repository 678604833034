"use client";
import ReactDOM from 'react-dom'
import logo from '../assets/DhandafyLogo.png'
import { Link } from 'react-scroll';
import { useState } from 'react';
import { useEffect } from 'react';
import { FaXmark, FaBars } from "react-icons/fa6";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsSticky(true);
        setIsMenuOpen(false)
      }
      else{
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navItems = [
    {link: "Home", path: "home" },
    {link: "Service", path: "service" },
    {link: "About", path: "about" },
    {link: "Testimonial", path: "testimonial" },
    {link: "FAQ", path: "faq" },
    // {link: "Get in Touch" , path: "contact"}
  ];
  return (
    <header className=" bg-white md:bg-white fixed top-0 left-0 right-0">
      <nav className={`py-4 lg:px-14 px-4 ${
          isSticky ? "sticky top-0 right-0 left-0 border bg-white transition-all duration-300" : ""
        }`}>
        <div className="flex justify-between items-center text-base gap-8">
          <a href="" className="text-2xl font-semibold flex items-center space-x-3"><img src={logo} alt="" className="w-28 inline-block items-center"/></a>

          <ul className="md:flex space-x-12 hidden">
            
            {
                navItems.map(({link, path}) => <Link to={path} spy={true} smooth={true} offset={-100} key={link} href={path} className="block text-base text-gray900 hover:text-brandPrimary first:font-medium">
                {link}
              </Link> )
            }
            <Link to='contact' className='hidden md:flex items-center cursor-pointer text-white bg-brandPrimary px-2 py-1 rounded-lg'>Get in touch</Link>
          </ul>


          {/* menu btn, visible on mobile screen */}
          <div className="md:hidden">
            <button
              onClick={toggleMenu}
              className="text-gray900 focus:outline-none focus:text-gray-500"
            >
              {isMenuOpen ? (
                <FaXmark  className="h-6 w-6 text-primary"/>
              ) : (
                <FaBars className="h-6 w-6 text-primary" />
              )}
            </button>
          </div>
        </div>

        <div
        className={`space-y-4 px-4 mt-16 py-7 bg-brandPrimary ${isMenuOpen ? "block fixed top-0 right-0 left-0" : "hidden"}`}
      >
        {
                navItems.map(({link, path}) => <Link 
                to={path} spy={true} smooth={true} offset={-90}
                key={link} 
                onClick={toggleMenu}
                className="block  text-white hover:text-gray-500"
                >
                {link}
              </Link> )
            }
      </div>
      </nav>
    </header>
  );
};

export default Navbar;
