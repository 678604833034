import React from "react";
import aboutImg from "../assets/about.png"

// motion
import { motion } from "framer-motion";
// variants
import { fadeIn } from "../variants";


const About = () => {
  return (
    <div>
        {/* about text */}
      <div className="px-4 lg:px-14 max-w-screen-2xl mx-auto my-8" id="about">
        <div className="md:w-11/12 mx-auto flex flex-col md:flex-row justify-between items-center gap-12">
        <motion.div
          variants={fadeIn("right", 0.2)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.6 }}
          >
            <img src={aboutImg} alt="" className="w-full"/>
          </motion.div>
          <motion.div
          variants={fadeIn("left", 0.3)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.5 }}
          className="md:w-3/5 mx-auto">
            <h2 className="text-4xl text-neutralDGrey font-semibold mb-4 md:w-4/5">
              The unseen of spending 2+ Years at DhandaFy
            </h2>
            <p className="md:w-3/4 text-sm text-neutralGrey mb-8">
             Dhandafy-In association with Saarthi AI was founded with a simple mission: to bring local vendors into the digital age. Inspired by the unique small businesses in our own neighborhoods, we saw a need for an accessible platform that connects these vendors with a wider audience.
             <br></br>
             Customers enjoy the convenience of exploring and purchasing from local vendors without leaving home. The platform offers a seamless online shopping experience tailored to local finds.
             Customers are connected to their community, learning more about the stories, people, and values behind each business. This fosters a meaningful connection to the local market and supports neighbor-to-neighbor commerce.
            </p>
            
          </motion.div>
        </div>
      </div>

      {/* company stats */}
     

    </div>
  );
};

export default About;
