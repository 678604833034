import React from 'react'

const Reviews = () => {
    const reviews= [
        {
            id: 1,
            title: "Amit Verma, Owner of Verma Groceries",
            description  : "Saarthi AI has transformed the way we do business. The ONDC integration and advanced POS systems have streamlined our operations and helped us reach more customers. Our sales have increased, and managing inventory is now a breeze!"
        },
        {
            id: 2,
            title: "Priya Sharma, Customer",
            description  : "Shopping with Saarthi AI's smart shopping cart was an amazing experience. It made my shopping trip so much easier and faster. I love the personalized recommendations and the smooth checkout process. Highly recommend!"
        },
        {
            id: 3,
            title: "Amit Verma, Owner of Verma Groceries",
            description  : "Saarthi AI has transformed the way we do business. The ONDC integration and advanced POS systems have streamlined our operations and helped us reach more customers. Our sales have increased, and managing inventory is now a breeze!"
        }
    ]
  return (
    <div className=' flex md:flex-row flex-col justify-between items-center bg-white mb-8' id='testimonial'>
        <div className=' flex flex-col'>
            <h1 className=' ml-5 font-semibold text-3xl text-gray-700'>You can hear what our<br></br> <span className=' text-mainBlue'>Customers</span> have to say</h1>
        </div>
        <div className='  flex md:flex-row flex-col gap-12  '>
            {reviews.map((review) => (
                <div
                 key={review.id}
                 className="px-4 py-8 text-center md:w-[300px] mx-auto md:h-50 rounded-md shadow cursor-pointer hover:-translate-y-5 hover:border-b-4  hover:border-mainBlue transition-all duration-300 flex flex-col  ">
                    <div>
                      <p className="text-sm text-neutralGrey">{review.description}</p>
                    </div>
                    <div className=' mt-10'>
                        <h1>{review.title}</h1>
                    </div>
                </div>  

            ))}

        </div>
      
    </div>
  )
}

export default Reviews
