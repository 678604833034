"use client";
import React from "react";
import { Carousel } from "flowbite-react";
import banner from "../assets/banner1.png";
import banner2 from "../assets/banner2.png";
import banner3 from "../assets/banner3.png";
import { Link } from "react-scroll";

const Home = () => {
  return (
    <div className=" bg-neutralSilver  mx-auto" id="home">
        <div className="my-28 md:my-8 py-12 flex flex-col w-full h-screen md:flex-row-reverse items-center justify-center gap-12">
           <div>
             <img src={banner} width={400} alt=""/>
            </div>
            {/* hero text */}
            <div className="md:w-1/2">
              <h1 className="text-5xl mb-4 font-semibold text-neutralDGrey md:w-3/4 leading-snug">Empowering Local Businesses </h1>
              <p className="text-neutralGrey text-base mb-8">Transforming Businesses with one Click</p>
              <Link to="contact">
               <button className="px-7 py-2 bg-brandPrimary text-white rounded hover:bg-neutralDGrey">Contact us</button>
              </Link>
            </div>
           
        </div>
    </div>
  )
}
export default Home;
        