
import React from "react";
import logo from '../assets/DhandafyLogo.png'

// import { FaFacebook } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";

function Footer() {
  const linkedinprofileurl = ' https://www.linkedin.com/company/saarthi-ai-pvt-ltd/';
  const instagramprofileurl = 'https://www.instagram.com/saarthi__ai_pvt.ltd?igsh=MW1oYTc0OGQ0d3NnMg==';
  const whatsappurl = 'https://whatsapp.com/channel/0029VacsP1aBfxoEvQPVxM2P';
  const email = 'intellicartcommunications@gmail.com';
  const mailtolink = `mailto:${email}`;
  return (
    <div className=" bg-white px-6  ">
      <div className="flex flex-col md:flex-row md:items-center  justify-evenly">
        <div className="mx-auto md:mx-0 py-4">
          <image src = {logo} width={300}></image>
          <div className="flex">
             <a
             href={linkedinprofileurl}
             target="_blank"
             rel="noopener noreferrer"
             className="text-2xl m-3 hover:cursor-pointer transition-all hover:text-blue-900 " >
              <FaLinkedin></FaLinkedin>
             </a>
           <a
           href={instagramprofileurl}
           target="_blank"
           rel="noopener noreferrer"
           className="text-2xl m-3 hover:cursor-pointer transition-all hover:text-pink-500 " >
            <FaInstagram></FaInstagram>
           </a>
           <a href={whatsappurl}
           target="_blank"
           rel="noopener noreferrer"
           className="text-2xl m-3 hover:cursor-pointer transition-all hover:text-green-500 " >
            <FaWhatsapp></FaWhatsapp>
           </a>
          </div>
        </div>
        <div>
          <h1 className="text-white text-bold text-xl md:mt-6">Company</h1>
          <ul>
            <li className="text-black my-2">About us</li>
            <li className="text-black my-2">Careers</li>
            <li className="text-black my-2">Blogs</li>
            <li className="text-black my-2">Business Tools</li>
          </ul>
        </div>
        <div>

        </div>
        <div className="md:mr-24 py-4 flex flex-col  gap-5">
          <div className=" flex flex-col justify-center items-start">
           <p className="ml-1  text-black font-extrabold  text-xl">
            We&apos;d love to hear from you!{" "}
            
          
           </p>
           <p className=" ml-2 font-light text-black text-sm">You can reach out to us at:</p>
          </div>
         
          <a href={`mailto:${email}`} className="  ml-2 link-underline text-xs  underline ">
              @intellicartcommunications@gmail.com
            

          </a>
        </div>
      </div>

      <div className="border-y border-y-white/20 py-4 items-center justify-between w-full mt-6">
        <div className="flex items-center justify-start gap-x-4">
          <a href="/legal/terms" className="text-white text-sm">
            Terms &amp; Conditions
          </a>
          <a href="/legal/privacy" className="text-white text-sm">
            Privacy Policy
          </a>
        </div>
        <p className="text-white text-sm">
          © Saarthi AI 2024. All Rights Reserved.
        </p>
      </div>
    </div>
  );
}

export default Footer;
