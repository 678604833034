import './App.css';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Footer from './components/Footer';
import Services from './components/Services';
import About from './components/About';
import Contact from './components/Contact';
import Reviews from './components/Reviews';
import FAQs from './components/FAQs';


function App() {
  return (
    <div >
      <Navbar className = "bg-white"></Navbar>
      <Home></Home>
      <Services></Services>
      <About></About>
      <Reviews></Reviews>
      <FAQs></FAQs>
      <Contact></Contact>
      <Footer></Footer>
      
    </div>
  );
}

export default App;
