import React from "react";
import { motion } from "framer-motion";
import market from "../assets/icons/market.png"
import cart from "../assets/icons/app.png"
import app from "../assets/icons/app.png"
import { fadeIn } from "../variants";

const Services = () => {
  const services = [
    {
      id: 1,
      title: "Dhandafy Local Marketplace",
      decription:"Discover and shop from local businesses with ease. Our platform connects you to a wide range of local products and services, promoting sustainable and community-focused shopping.",
      image: {market}
      ,
    },
    {
      id: 2,
      title: "Smart Shopping Cart",
      decription:
        "Revolutionize your shopping experience with our AI-powered smart shopping carts. Enjoy a seamless, hassle-free, and personalized shopping journey with real-time assistance and effortless checkout.",
      image: {cart}
    },
    {
      id: 3,
      title: "Mobile App",
      decription:
        "Stay connected on the go with our intuitive mobile app. Find local stores, track your orders, and get exclusive deals right at your fingertips.",
      image: {app},
    },
  ];
  return (
    <div className="md:px-14 px-4 py-16 max-w-screen-2xl mx-auto" id="service">
        <motion.div
        variants={fadeIn("up", 0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.7 }}
        className="text-center my-8"
      >
        <h2 className="text-4xl text-neutralDGrey font-semibold mb-2">
          Our Services
        </h2>
        <p className="text-neutralGrey">
          for our customers
        </p>
        </motion.div>

      {/* service cards */}
      <motion.div
        variants={fadeIn("left", 0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.7 }}
        className="mt-20 text-center md:w-1/2 mx-auto"
      >
        <h2 className="text-4xl text-neutralDGrey font-semibold mb-3">
          Manage your entire community in a single system
        </h2>
      </motion.div>

      <motion.div
        variants={fadeIn("up", 0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.7 }}
        className="mt-14 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 md:w-11/12 mx-auto gap-12"
      >
        {services.map((service) => (
          <div
            key={service.id}
            className="px-4 py-8 text-center md:w-[300px] mx-auto md:h-80 rounded-md shadow cursor-pointer hover:-translate-y-5 hover:border-b-4  hover:border-mainBlue transition-all duration-300 flex items-center justify-center h-full"
           >
            <div className="">
              <div className="bg-[#E8F5E9] w-14 h-14 mx-auto mb-4 rounded-tl-3xl rounded-br-3xl ">
                <img src={service.image} alt="" className="-ml-5  fill-brandPrimary" width={10} height={10} />
              </div>
              <h4 className="text-2xl font-bold text-neutralDGrey mb-2 px-2">
                {service.title}
              </h4>
              <p className="text-sm text-neutralGrey">{service.decription}</p>
            </div>
          </div>
        ))}
      </motion.div>
    </div>
  );
};

export default Services;
